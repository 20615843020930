const ACTION = {
    // SIGN UP
    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_REQUEST_TEACHER: 'SIGN_UP_REQUEST_TEACHER',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
    // LOGIN
    USER_LOG_IN: 'USER_LOG_IN',
    SIGN_UP_FAILURE: 'SIGN_UP_FALURE',
    // GET USER DATA 
    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    // GET TEACHER COURSES DATA
    GET_TEACHER_COURSE: 'GET_TEACHER_COURSE',
    GET_TEACHER_COURSE_SUCCESS: 'GET_TEACHER_COURSE_SUCCESS',
    // GET COURSES
    GET_COURSE: "GET_COURSE",
    GET_COURSE_SUCCESS: "GET_COURSE_SUCCESS",
    // GET COURSES CATEGORY
    GET_COURSE_CATEGORY: "GET_COURSE_CATEGORY",
    GET_COURSE_CATEGORY_SUCCESS: "GET_COURSE_CATEGORY_SUCCESS",
    // GET TITLE BY SEARCH
    GET_TITLE_BY_SEARCH: "GET_TITLE_BY_SEARCH",
    GET_TITLE_BY_SEARCH_SUCCESS: "GET_TITLE_BY_SEARCH_SUCCESS",
    // GET COURSES DETAIL
    GET_COURSE_DETAIL: 'GET_COURSE_DETAIL',
    GET_COURSE_DETAIL_SUCCESS: 'GET_COURSE_DETAIL_SUCCESS',
    // GET LESSONS
    GET_LESSONS:'GET_LESSONS',
    GET_LESSONS_SUCCESS: 'GET_LESSONS_SUCCESS',
    // GET ENROLLMENT
    ENROLL_COURSE: 'ENROLL_COURSE',
    ENROLLMENT_SUCCESS: 'ENROLLMENT_SUCCESS',
    ENROLLMENT_FAILED: 'ENROLMENT_FAILED',
    // GET ENROLLED COURSES
    GET_ENROLLED_COURSE: 'GET_ENROLLED_COURSE',
    GET_ENROLLED_COURSE_SUCCESS: 'GET_ENROLLED_COURSE_SUCCESS',
    // ENROLL CHECK
    ENROLL_CHECK: 'ENROLL_CHECK',
    ENROLL_CHECKED: 'ENROLL_CHECKED',
    // GET LESSON BY ENROLLED COURSE
    GET_LESSON_BY_ENROLLED_COURSE: 'GET_LESSON_BY_ENROLLED_COURSE',
    GET_LESSON_BY_ENROLLED_COURSE_SUCCESS: 'GET_LESSON_BY_ENROLLED_COURSE_SUCCESS',
    // ADD DATA
    ADD_COURSE: "ADD_COURSE",
    ADD_COURSE_SUCCESS: "ADD_COURSE_SUCCESS",
    ADD_LESSON: "ADD_LESSON",
    ADD_LESSON_SUCCESS: "ADD_LESSON_SUCCESS",
    GET_STUDENT: "GET_STUDENT",
    GET_STUDENT_SUCCESS: "GET_STUDENT_SUCCESS",
    // GET SAVED COURSE
    GET_SAVED_COURSE: 'GET_SAVED_COURSE',
    GET_SAVED_COURSE_SUCCESS: 'GET_SAVED_COURSE_SUCCESS'
};
export default ACTION;
